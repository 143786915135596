<template>
    <div class="banner-container">
        <div class="article-item" v-for="(item, index) in recentArticles" :key="index">
            <router-link class="blog-list-item" :to="{ name: item.name }">
                <img :src="item.poster" :alt="item.title" class="article-img" />
                <h3 class="article-title">{{ item.title }}</h3>
                <p class="article-date">{{ item.date }}</p>
            </router-link>
        </div>
    </div>
</template>

<script>
import doc from '@/documents/blogs.js';
export default {
    name: 'Banner',
    data() {
        return {
            recentArticles: doc.bloglist.en.slice(-3),
        };
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.banner-container {
    background-color: #2c3338;
    /* 背景色 */
    color: #fff;
    padding: 15px;
    /* 内边距 */
    display: grid;
    /* grid布局 */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* 自适应列，最小300px */
    gap: 15px;
    cursor: pointer; // 鼠标变成小手
}

.article-item {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jump-url :hover {
    color: #d6d5d5;
}

.article-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.article-title {
    color: #fff;
    margin: 10px 0;
}

.article-title:hover {
    color: #b6adad;
    // background-color: #3b3939;
}

.article-date {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 15px;
}

/* 移动端适配（竖排排列） */
@media (max-width: 768px) {
    .banner-container {
        grid-template-columns: 1fr;
        /* 移动端单列布局 */
    }

    .article-item {
        max-width: 100%;
        margin-bottom: 20px;
    }
}
</style>