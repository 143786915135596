<template>
  <div id="app">
		<!-- <to-top class="totop" /> -->
    <ToTop />

    <router-view />
    <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />
    <FixedQrCode v-if="!$global.isMobileDevice()"/>
  </div>
</template>

<script>
// import ClientOnly from 'vue-client-only';
import ToTop from './components/toTop.vue';
import FixedQrCode from './components/FixedQrCode.vue';


export default {
  name: 'App',
  components: {
    // ClientOnly,
    ToTop,
    FixedQrCode,
  },
  data () {
    return {
      myApp: null,
      screenWidth: null,
      is_mobile: null,
    };
  },
  computed: {
    deviceType () {
      // 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
      if (this.$ssrContext) {
        console.log('ssr');
        return this.$ssrContext.deviceType;
      }

      // 在客户端渲染期间，使用窗口宽度检测设备类型
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      return windowWidth < 768 ? 'mobile' : 'desktop';
    },
    isMobile () {
      return this.deviceType === 'mobile';
    },
  },
  mounted () {
    console.log(process.env);
    this.myApp = this.$store.state.gb;
    this.$logEvent('show_gbhome');

    setTimeout(() => {
      this.showTeleMsg = false;
    }, 2000);
  },

  methods: {
    _isMobile () {
      if (typeof navigator !== 'undefined') {
        // 在浏览器环境中执行的代码
        // 使用 navigator 对象进行相关操作
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      } else {
        // 在服务器环境中执行的代码
        // 忽略或提供替代方案
        return false;
      }
    },
  },
};
</script>
